:root { 
--gray_900_14:#060c3b14; 
--bluegray_50:#eceef1; 
--white_A700_7e:#ffffff7e; 
--gray_900_99:#060b3b99; 
--light_green_A700:#7fba00; 
--red_800:#ce0f24; 
--light_blue_500:#00a4ef; 
--green_500:#4caf50; 
--white_A700_99:#ffffff99; 
--gray_600_6c:#6c6b7c6c; 
--light_green_800:#5e8e3e; 
--deep_orange_300:#f9896b; 
--pink_500:#e91e63; 
--deep_orange_301:#ff7f5c; 
--red_A700:#e50914; 
--gray_600:#6c6b7c; 
--gray_601:#737373; 
--lime_700:#d2ad28; 
--amber_300:#ffdb5f; 
--gray_200:#e6e7eb; 
--blue_50:#dae8fe; 
--blue_51:#e6efff; 
--bluegray_600:#5d6180; 
--black_900_11:#00000011; 
--bluegray_200:#b4b6c4; 
--white_A700:#ffffff; 
--cyan_A400:#00e1f0; 
--red_900:#b1060f; 
--light_blue_A200:#36c5f0; 
--red_300:#d88383; 
--amber_A200:#ffd43c; 
--gray_50:#edffff; 
--teal_200:#84c5c5; 
--deep_purple_300:#988fce; 
--green_400:#66bb6e; 
--teal_400:#20a2a2; 
--teal_202:#84c6c6; 
--teal_201:#8cd0d0; 
--teal_401:#209fa0; 
--black_900:#000000; 
--light_green_500:#95bf47; 
--deep_orange_600:#f25022; 
--yellow_700:#e1b92d; 
--gray_901:#1b1c31; 
--indigo_900_0c:#1c24690c; 
--amber_600:#ffb900; 
--white_A700_a2:#ffffffa2; 
--gray_900:#060b3b; 
--bluegray_100:#c6d5d5; 
--orange_500:#ff9800; 
--gray_300:#dbdde2; 
--bluegray_701:#446b6b; 
--cyan_300:#4ddbdb; 
--indigo_100:#cad5e7; 
--bluegray_700:#456b6b; 
--indigo_900:#002f86; 
--indigo_700:#3734a9; 
--indigo_500:#4f46ba; 
}